class ContactUsValidate {
  constructor(form) {
    this.errorMessage = {};
    this.errorMessageWrap = form.querySelector('.error-message');
    this.inputs = form.querySelectorAll('input:not([type="button"])');
  }

  validateInput() {
    let valid = true;
    this.errorMessage = {};

    this.inputs.forEach(function (input) {
      input.classList.remove('error');

      if (input.value === '') {
        const message = 'Please fill out all fields.';
        this.errorMessage[message] = message;
        input.classList.add('error');
        valid = false;
      }

      if (input.getAttribute('id') === 'email'
        && !this.validateEmail(input.value)) {
        const message = 'Please use a valid email address.';
        this.errorMessage[message] = message;
        input.classList.add('error');
        valid = false;
      }

    }, this);

    valid ? this.showHideErrorMessage('hide') : this.showHideErrorMessage('show');

    return valid;
  }

  validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  showHideErrorMessage(action) {

    if (action === 'show') {
      this.errorMessageWrap.innerHTML = Object.values(this.errorMessage).join('<br>');
      this.errorMessageWrap.style.display = 'block';

    } else {
      this.errorMessageWrap.innerHTML = '';
      this.errorMessageWrap.style.display = 'none';
    }
  }

}